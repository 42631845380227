import { createSlice } from '@reduxjs/toolkit';

import { createAsyncAction } from 'src/utils/createAsyncAction';
import SettingsRepository from 'src/repositories/settings/SettingsRepository';
import { SettingKeys } from 'src/types/settings';

import type { RootState } from '.';

export type SettingsStateType = {
  data: Record<SettingKeys, string | null>;
  loading: boolean;
};

export type TalentsSliceActionsType = {
  loadSettings: () => void;
};

const initialState: SettingsStateType = {
  data: {
    talent_usage_fee: null,
    w2_org_markup: null,
    w2_talent_markdown: null,
  },
  loading: false,
};

export const loadSettings = createAsyncAction('settings', SettingsRepository.index);

const talentsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(loadSettings.pending, state => {
      state.loading = true;
    });
    builder.addCase(loadSettings.fulfilled, (state, { payload }) => {
      payload.forEach(setting => {
        state.data[setting.name] = setting.value;
      });
      state.loading = false;
    });
  },
});

export const selectSettingsTalentUsageFee = (state: RootState): number | null =>
  state.settings.data.talent_usage_fee ? Number(state.settings.data.talent_usage_fee) : null;
export const selectW2OrgMarkup = (state: RootState): number | null =>
  state.settings.data.w2_org_markup ? Number(state.settings.data.w2_org_markup) : null;
export const selectW2TalentMarkdown = (state: RootState): number | null =>
  state.settings.data.w2_talent_markdown ? Number(state.settings.data.w2_talent_markdown) : null;
export const selectSettingsLoading = (state: RootState): SettingsStateType['loading'] => state.settings.loading;

export default talentsSlice.reducer;
